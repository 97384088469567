<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "@/core/services/store";

defineProps({
  showBackButton: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  subtitle: {
    type: String,
    default: ""
  },
  items: {
    type: Array,
    default: () => []
  }
});

const store = useStore();
const isLoading = computed(
  () => store.getters["loadingQueue/showLoadingBar"]() ?? false
);
</script>

<template>
  <div
    class="d-flex justify-space-between align-items-center mb-4 connect-header"
  >
    <div class="d-flex align-items-center">
      <slot name="contentLeft" />
      <button v-if="showBackButton" class="btn btn-icon" @click="$emit('back')">
        <i class="fa-light fa-chevron-left" />
      </button>
      <div class="d-flex flex-column">
        <span class="text-h5">
          {{ title }}
        </span>
        <span class="text-muted">
          {{ subtitle }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <slot name="beforeItems" />
      <template v-for="(item, key) in items">
        <button
          v-if="item.type === 'button'"
          :key="key"
          v-b-popover.hover.bottom="item.tooltip"
          class="btn"
          :class="(key > 0 ? 'ml-2 ' : '') + (item.class ?? 'btn-primary')"
          :disabled="item.disabled || (item.disabledWhenLoading && isLoading)"
          @click="$emit(item.emit)"
        >
          <i v-if="item.icon" :class="item.icon" />
          {{ item.title }}
        </button>
        <span
          v-else
          :key="key"
          v-b-popover.hover.bottom="item.tooltip"
          :class="(key > 0 ? 'ml-2 ' : '') + item.class"
          @click="$emit(item.emit)"
        >
          <i v-if="item.icon" :class="item.icon" />
          {{ item.title }}
        </span>
      </template>
      <slot name="afterItems" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.connect-header {
  min-height: 45px;
}

.btn {
  i {
    padding: 0;
  }
}
</style>
