import { computed, ref } from "vue";
import Forms from "@/components/ExternalApps/classes/Forms";

const forms = ref();
const formsConfiguration = computed(() => forms.value?.configuration);
const formsAuthentication = computed(() => forms.value?.authentication);

// Get all technical names from form to filter for the correct config values
const formsConfigurationFieldNames = computed(() => {
  let fieldNames = [];
  if (!Object.keys(formsConfiguration.value || {}).length) return;

  for (const item in formsConfiguration.value) {
    for (const form in formsConfiguration.value[item]) {
      fieldNames.push(
        ...formsConfiguration.value[item][form].map(item => item.name)
      );
    }
  }
  return fieldNames;
});

async function loadForms() {
  await Forms.getWatchdogForm()
    .then(response => {
      forms.value = response.data;
    })
    .catch(error => Error(error));
}

export const useWatchDogFormService = () => {
  return {
    formsConfigurationFieldNames,
    formsConfiguration,
    formsAuthentication,
    loadForms
  };
};
