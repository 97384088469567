export const formConfig = {
  labelStacked: false,
  enableVariables: false,
  enableTypecast: false,
  labelColClass: "col-7",
  fieldColClass: "col-5",
  fieldColClassXl: "col-5",
  snippetPrefix: "watchDog"
};

export const shopModulesNotificationEnv = {
  shopimporter_amazon: process.env.VUE_APP_WATCHDOG_APP_AMAZON_WORKLFLOW_ID,
  shopimporter_shopify: process.env.VUE_APP_WATCHDOG_APP_SHOPIFY_WORKLFLOW_ID,
  shopimporter_shopware:
    process.env.VUE_APP_WATCHDOG_APP_SHOPWARE5_WORKLFLOW_ID,
  shopimporter_shopware6:
    process.env.VUE_APP_WATCHDOG_APP_SHOPWARE6_WORKLFLOW_ID,
  shopimporter_woocommerce:
    process.env.VUE_APP_WATCHDOG_APP_WOOCOMMERCE_WORKLFLOW_ID
};
