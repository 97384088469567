<script setup>
import Header from "@/components/Tools/Header/Header.vue";
import Card from "@/components/Tools/Card/Card.vue";
import { useProjectService } from "@/components/ExternalApps/WatchDog/composables/useProjectService";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Presets from "@/components/Settings/Presets/presets";
import { computed, onMounted, ref } from "vue";
import { formConfig } from "@/components/ExternalApps/WatchDog/config/general";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Loader from "@/components/Tools/Loader.vue";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import Tabs from "@/components/Tools/Tabs.vue";
import { usePresetService } from "@/components/ExternalApps/WatchDog/composables/usePresetService";
import { useWatchDogFormService } from "@/components/ExternalApps/WatchDog/composables/useWatchDogFormService";

const { project } = useProjectService();
const { preset, getPresetByName } = usePresetService();
const { formsAuthentication } = useWatchDogFormService();

const moduleName = computed(() =>
  preset?.value?.value.modulename.replace("shopimporter_", "")
);
const formFromModule = computed(
  () => formsAuthentication.value?.[selectedTab.value]?.[moduleName.value]
);
const form = computed(() => formsAuthentication.value?.[selectedTab.value]);
const activeData = ref();
const authData = ref();
const authAvailable = computed(() => {
  return Object.keys(preset?.value || {}).length;
});
const isLoading = computed(() =>
  useStore().getters["loadingQueue/showLoadingBar"]("getPreset", "get")
);
const headerItems = computed(() => [
  {
    type: "button",
    title: i18n.t("general.save"),
    emit: "save",
    icon: "floppy-disk",
    disabled: !authAvailable.value
  }
]);
const tabItems = [
  {
    key: "authentication"
  },
  {
    key: "active"
  }
];
const selectedTab = ref(tabItems[0].key);
// Xentral authentication data from Config API
const presetAuthData = computed(
  () => preset?.value?.value?.einstellungen_json?.felder ?? []
);

function selectTab(value) {
  selectedTab.value = value;
}

function setActiveData() {
  activeData.value = { active: preset?.value?.value.aktiv === 1 };
}

function setAuthData() {
  const values = {};
  for (const key of formFromModule.value) {
    values[key.name] = presetAuthData.value[key.name] ?? "";
  }
  authData.value = values;
}

async function savePreset() {
  if (!authAvailable.value) return;

  addEventToLoadingQueue({ key: "savePreset", group: "save" });
  // "aktiv" needs to be an integer; It is in DE because the xentral config API key is in DE
  preset.value.value.aktiv = activeData.value.active === true ? 1 : 0;
  for (const index in authData.value) {
    presetAuthData.value[index] = authData.value[index];
  }

  await Presets.update(preset.value.id, preset.value);
  removeEventFromLoadingQueue({ key: "savePreset", group: "save" });
}

onMounted(async () => {
  await getPresetByName("salesChannelConfig");

  if (authAvailable.value) {
    setActiveData();
    setAuthData();
  }
});
</script>

<template>
  <div>
    <Header
      :items="headerItems"
      :show-back-button="true"
      :title="$t('watchDog.authentication') + ' ' + project.name"
      @back="$router.push({ name: 'watchDogProjects' })"
      @save="savePreset"
    />
    <div v-if="isLoading">
      <Loader></Loader>
    </div>
    <Card v-else class="pa-6">
      <Tabs
        v-if="authAvailable"
        :items="tabItems"
        translate-prefix="watchDog"
        @on-select-tab="selectTab"
      >
        <template v-if="selectedTab === 'authentication'">
          <FormHelper
            v-model="authData"
            :config="formConfig"
            :form="formFromModule"
          />
        </template>
        <template v-else-if="selectedTab === 'active'">
          <FormHelper v-model="activeData" :config="formConfig" :form="form" />
        </template>
      </Tabs>

      <div v-else>
        {{ $t("watchDog.noAuthAvailable") }}
      </div>
    </Card>
  </div>
</template>

<style lang="scss" scoped></style>
