import { computed } from "vue";
import { useStore } from "@/core/services/store";
import { useRoute } from "vue-router/composables";

const store = useStore();

export const useProjectService = () => {
  const route = useRoute();

  const projects = computed(() => {
    return (
      store.getters.projects.filter(
        project =>
          project.preset_id === process.env.VUE_APP_WATCHDOG_APP_PRESET_ID
      ) ?? []
    );
  });

  const project = computed(() => {
    const routeId = route.params?.id ?? "";

    if (!routeId) return;

    return store.getters.project(routeId);
  });

  const projectId = computed(() => project.value.id);

  const xentralProjectId = computed(() => {
    return store.getters.projects.find(project => project.name === "Xentral")
      ?.id;
  });

  return { projects, project, projectId, xentralProjectId };
};
