import { ref } from "vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Presets from "@/components/Settings/Presets/presets";
import { useProjectService } from "@/components/ExternalApps/WatchDog/composables/useProjectService";

export const usePresetService = () => {
  const { projectId } = useProjectService();
  const preset = ref();

  async function getPresetByName(presetName, xProjectId = projectId.value) {
    addEventToLoadingQueue({ key: "getPreset", group: "get" });

    const params = {
      noPagination: true
    };
    const filter = [
      {
        key: "name",
        op: "equals",
        value: presetName
      }
    ];
    const headers = {
      "x-project-id": xProjectId
    };

    const response = await Presets.getAll(params, filter, headers, true);

    if (response.data.length > 0) {
      preset.value = response.data[0];
    }

    removeEventFromLoadingQueue({ key: "getPreset", group: "get" });
  }

  return { preset, getPresetByName };
};
